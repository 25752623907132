import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://www.youtube.com/embed/z1O-A6_3efQ"
    bibleGroupSrc="https://pdfhost.io/v/WI8nmfBf7_Bible_Group_Homework_111pdf.pdf"
    bibleGroupText="Bible Group Homework"
    notesText="Sermon Notes"
    notesSrc="http://bible.com/events/46471916"
  >
    <SEO title="Jesus and the Bible - Holiday on its Head" />
  </Layout>
)

export default SermonPost
